import React from 'react';
import './App.css';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Button, Card, Container, Divider, Stack, Typography } from '@mui/material';
import MyMenu from './components/MyMenu';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PricesPage from './routes/Prices';
import Subscriptions from './routes/Subscriptions';

function App() {
  const { instance } = useMsal();

  return (
    <div className="App">
        <UnauthenticatedTemplate>
        <header className="App-header">
            <Container maxWidth="sm">
              <Card>
                  <Stack
                    direction="row" 
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={4}
                    padding={2}
                    alignItems="center"
                    justifyContent="space-evenly">
                    <img src="pngegg.png" height={200} alt="Elektrisiti"/>
                    <Stack direction="column" alignSelf="stretch" justifyContent="space-evenly">
                      <Typography fontSize="2.4rem" variant="h1">Elektrisiti</Typography>
                      <Button onClick={() => instance.loginPopup()} variant="contained">Login</Button>
                    </Stack>
                  </Stack>
                </Card>
            </Container>
          </header>
        </UnauthenticatedTemplate>
      
        <AuthenticatedTemplate>
          <BrowserRouter>
            <div className="App-body">
              <Container> 
                <MyMenu/>
                <Routes>
                  <Route path="/" element={<PricesPage />} />
                  <Route path="/subscriptions" element={<Subscriptions/>}/>
                </Routes>
              </Container>
            </div>
          </BrowserRouter>
        </AuthenticatedTemplate>
      
    </div>
  );
}

export default App;
