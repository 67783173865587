import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const msalConfig = { // TODO: this must live longer!!
  auth: {
      clientId: '477795e6-30ba-49e8-90d4-366a0546bc21',
      authority: 'https://login.microsoftonline.com/ca797089-7e98-4b8c-bf72-48b3dc4ec873',
  },
  cache: {
    storeAuthStateInCookie: true
  }
}; 

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js') // Path to your service worker file
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Error registering Service Worker:', error);
      });
  });
}

const pca = new PublicClientApplication(msalConfig);
axios.defaults.baseURL = "https://api.elektrisiti.eu/api/"; // TODO: need to add to config
axios.interceptors.request.use(
  async (request) => {
    const account = pca.getAllAccounts()[0];
    const msalResponse = await pca.acquireTokenSilent({
      account: account,
      scopes: ["477795e6-30ba-49e8-90d4-366a0546bc21/user_impersonation"],
    });
    request.headers.Authorization = `Bearer ${ msalResponse.accessToken }`;
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
    </MsalProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();