import { Card } from "@mui/material";
import React from "react";
import Price from "../components/Price";

const PricesPage = () => {
    return (
        <Card>
            <h1>Prices</h1>
            <Price></Price>
        </Card>
    );
}

export default PricesPage;