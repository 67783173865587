import React from "react";
import { useMsal } from "@azure/msal-react";
import { AppBar, Container, Toolbar, Typography, Box, Button } from "@mui/material";
import { NavLink } from "react-router-dom";

function MyMenu() {
    const {instance} = useMsal();

    return (
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Elektrisiti
            </Typography>
            
            <Box sx={{ flexGrow: 1 }}>
              <NavLink to="/"><Button variant="text" sx={{color: "white"}}>Prices</Button></NavLink>
              <NavLink to="/subscriptions"><Button variant="text" sx={{color: "white"}}>Subscriptions</Button></NavLink>
            </Box>
  
            <Box sx={{ flexGrow: 0 }}>
              <Button onClick={() => instance.logout()} variant="contained" color="secondary">Logout</Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
}

export default MyMenu;