import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Card, CircularProgress } from "@mui/material";


const Prices = () => {
    
    const getPrices = async () => {
        const response = await axios.get("v1/prices");
        return response.data.currentPrice;
    };

    const { data: price, isLoading } = useQuery("pricesData", getPrices);
    
    if (isLoading) return <div><CircularProgress />Fetching price...</div>;

    return (
        <>
            <Card sx={{padding: 2}}>
                Current price: {price}
            </Card>
        </>
    );
};
export default Prices;