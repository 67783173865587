import { Button, Card, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

const Subscriptions = () => {
    const [subscription, setSubscription] = useState<any>(null);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const getSubscriptions = async () => {
        const response = await axios.get("v1/subscriptions");
        return response.data;
    };

    const {
        data: subscriptions,
        isLoading,
        refetch,
    } = useQuery("subscriptionsData", getSubscriptions);

    if (isLoading)
        return (
            <div>
                <CircularProgress />
                Fetching subscriptions...
            </div>
        );


        const subs = navigator.serviceWorker.ready.then(
            async (registration) => {
                return await registration.pushManager.getSubscription();
            }
            
        );


    const subscribeToPush = async () => {
        try {
            const subs = navigator.serviceWorker.ready.then(
                async (registration) => {
                    await registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey:
                            "BC57gG7ceMJomg46T834dxg2Q0XJWlZzvDZEIxlwCooMsGsQUnSHc8LR9zJrMRdizjbZ6zoLkbDCy9H0Lkcaouo",
                    });
                    return await registration.pushManager.getSubscription();
                }
            );
            const subsi = await subs;
            await axios.post("v1/subscriptions", subsi);
            refetch();
            setSubscription(subsi);
        } catch (error) {
            console.error("Error subscribing to push notifications:", error);
        }
    };

    const deleteSubscription = async (id: string) => {
        await axios
            .delete("v1/subscriptions/" + id)
            .then(() => {
                refetch();
            })
            .catch((error) => {
                setMessage(error.message);
                setOpen(true);
            });
    };

    return (
        <Card>
            {/* TODO: create nice subcsription management like in piletid... */}
            <h1>Subscriptions</h1>
            <Button variant="contained" onClick={subscribeToPush}>
                Subscribe to Push Notifications
            </Button>
            {subscription && <p>Push notifications subscribed!</p>}

            {subscriptions.length &&
                subscriptions.map((sub: { id: string, subscription: any }, i: number) => {
                    return (
                        <div key={i}>
                            {sub.id}
                            <Button
                                variant="outlined"
                                onClick={() => deleteSubscription(sub.id)}
                                sx={{marginLeft: 2}}
                            >
                                Delete
                            </Button>
                        </div>
                    );
                })}
            <Snackbar
                open={open}
                autoHideDuration={4000}
                message={message}
            ></Snackbar>
        </Card>
    );
};

export default Subscriptions;
